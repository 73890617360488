/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '@uppy/golden-retriever/lib/ServiceWorker';
import Uppy from '@uppy/core';
import GoldenRetriever from '@uppy/golden-retriever';
// import Instagram from '@uppy/instagram';
import FileInput from '@uppy/file-input';
// import GoogleDrive from '@uppy/google-drive';
import Webcam from '@uppy/webcam';
import Transloadit from '@uppy/transloadit';
// import XHRUpload from '@uppy/xhr-upload';
import French from '@uppy/locales/lib/fr_FR';

import { DashboardModal } from '@uppy/react';

import { dashboardLocale, webcamLocale } from '../../lib/uppy-locales';

import styles from '../../../styles/partials/upload.scss';

const propTypes = {
    children: PropTypes.node,
    onComplete: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
};

const Upload = ({ onComplete, children, className }) => {
    const [dashboardOpen, toggleDashboard] = useState(false);

    const uppyInstance = useMemo(() => {
        const uppy = Uppy({
            debug: true,
            meta: { type: 'avatar' },
            restrictions: {
                maxFileSize: 1024 * 1024 * 20, // 20 mb max
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/*'],
            },
            autoProceed: true,
            locale: French,
        });

        uppy.use(Transloadit, {
            service: 'https://api2.transloadit.com',
            params: {
                auth: { key: 'e517a2700d754087b372130a15ac1188' },
                template_id: '7a72e3c6290f4128a7614a768b434b00',
            },
            waitForEncoding: true,
            limit: 1,
        })
            .use(FileInput, {
                target: null,
                pretty: true,
                inputName: 'file',
                locale: {},
                companionUrl: Transloadit.COMPANION,
                companionAllowedHosts: Transloadit.COMPANION_PATTERN,
            })
            // Note: you can use this in parallel with the other

            .use(GoldenRetriever)
            // .use(Instagram, {
            //     companionUrl: Transloadit.COMPANION,
            //     companionAllowedHosts: Transloadit.COMPANION_PATTERN,
            // })
            // .use(GoogleDrive, {
            //     companionUrl: Transloadit.COMPANION,
            //     companionAllowedHosts: Transloadit.COMPANION_PATTERN,
            // })
            // .use(XHRUpload, {
            //     endpoint: '/api/upload',
            //     fieldName: 'file',
            //     // headers: {
            // })
            .use(Webcam, {
                countdown: false,
                modes: ['picture'],
                mirror: true,
                facingMode: 'user',
                locale: webcamLocale,
            });

        uppy.on('complete', result => {
            result.successful.forEach(successful => {
                console.log('complete', result, successful); // eslint-disable-line
                // TODO: bug with non-standard uploads???
                onComplete(successful.data); // This is the File
                toggleDashboard(false);
            });
        });

        return uppy;
    });

    useEffect(() => {
        return () => {
            uppyInstance.close();
        };
    }, [uppyInstance]);

    return (
        <div className={classNames(styles.container)}>
            <button
                className={classNames([
                    styles.button,
                    {
                        [className]: className !== null,
                    },
                ])}
                type="button"
                onClick={() => {
                    toggleDashboard(!dashboardOpen);
                }}
            >
                {children}
            </button>
            <DashboardModal
                uppy={uppyInstance}
                open={dashboardOpen}
                onRequestClose={() => toggleDashboard(false)}
                closeModalOnClickOutside
                plugins={['Webcam', 'FileInput']}
                locale={dashboardLocale}
            />
        </div>
    );
};

Upload.propTypes = propTypes;
Upload.defaultProps = defaultProps;

export default Upload;
