export const dashboardLocale = {
    strings: {
        // When `inline: false`, used as the screen reader label for the button that closes the modal.
        closeModal: 'Fermer la fenêtre',
        // Used as the screen reader label for the plus (+) button that shows the “Add more files” screen
        addMoreFiles: 'Ajouter une image',
        // Used as the header for import panels, e.g., “Import from Google Drive”.
        importFrom: 'Importer de %{name}',
        // When `inline: false`, used as the screen reader label for the dashboard modal.
        dashboardWindowTitle: 'Fenêtre de sélection (Appuyez sur échapper pour fermer)',
        // When `inline: true`, used as the screen reader label for the dashboard area.
        dashboardTitle: 'Fenêtre de sélection',
        // Shown in the Informer when a link to a file was copied to the clipboard.
        copyLinkToClipboardSuccess: 'Lien copié au presse-papier',
        // Used when a link cannot be copied automatically — the user has to select the text from the
        // input element below this string.
        copyLinkToClipboardFallback: 'Copiez le lien ci-dessous',
        // Used as the hover title and screen reader label for buttons that copy a file link.
        copyLink: 'Copier le lien',
        // Used as the hover title and screen reader label for file source icons, e.g., “File source: Dropbox”.
        fileSource: 'Source du fichier: %{name}',
        // Used as the label for buttons that accept and close panels (remote providers or metadata editor)
        done: 'Terminé',
        // Used as the screen reader label for buttons that remove a file.
        removeFile: 'Enlever une image',
        // Used as the screen reader label for buttons that open the metadata editor panel for a file.
        editFile: 'Modifier une image',
        // Shown in the panel header for the metadata editor. Rendered as “Editing image.png”.
        editing: 'Modifier %{file}',
        // Text for a button shown on the file preview, used to edit file metadata
        edit: 'Modifier',
        // Used as the screen reader label for the button that saves metadata edits and returns to the
        // file list view.
        finishEditingFile: 'Terminer les modifications',
        // Used as the label for the tab button that opens the system file selection dialog.
        myDevice: 'Mon appareil',
        // Shown in the main dashboard area when no files have been selected, and one or more
        // remote provider plugins are in use. %{browse} is replaced with a link that opens the system
        // file selection dialog.
        dropPasteImport: "Déposez ou collez l'image ici, %{browse} ou importez de:",
        // Shown in the main dashboard area when no files have been selected, and no provider
        // plugins are in use. %{browse} is replaced with a link that opens the system
        // file selection dialog.
        dropPaste: "Déposer l'image ici, la coller ou naviguer",
        // This string is clickable and opens the system file selection dialog.
        browse: 'naviguez',
        // Used as the hover text and screen reader label for file progress indicators when
        // they have been fully uploaded.
        uploadComplete: 'Téléversement terminé',
        // Used as the hover text and screen reader label for the buttons to resume paused uploads.
        resumeUpload: 'Reprendre le téléversement',
        // Used as the hover text and screen reader label for the buttons to pause uploads.
        pauseUpload: 'Suspendre le téléversement',
        // Used as the hover text and screen reader label for the buttons to retry failed uploads.
        retryUpload: 'Réessayer',

        // Used in a title, how many files are currently selected
        xFilesSelected: {
            0: '%{smart_count} fichier sélectionné',
            1: '%{smart_count} fichiers sélectionnés',
        },

        // @uppy/status-bar strings:
        // Shown in the status bar while files are being uploaded.
        uploading: 'Chargement',
        // Shown in the status bar once all files have been uploaded.
        complete: 'Terminé',
        // Shown in the status bar if an upload failed.
        uploadFailed: 'Le téléversement a échoué',
        // Shown in the status bar while the upload is paused.
        paused: 'En pause',
        // Used as the label for the button that retries an upload.
        retry: 'Réessayer',
        // Used as the label for the button that cancels an upload.
        cancel: 'Annuler',
        // Used as the screen reader label for the button that retries an upload.
        // Used as the screen reader label for the button that cancels an upload.
        cancelUpload: 'Annuler le téléversement',
        // When `showProgressDetails` is set, shows the number of files that have been fully uploaded so far.
        filesUploadedOfTotal: {
            0: '%{complete} de %{smart_count} fichier envoyé',
            1: '%{complete} de %{smart_count} fichiers envoyés',
        },
        // When `showProgressDetails` is set, shows the amount of bytes that have been uploaded so far.
        dataUploadedOfTotal: '%{complete} de %{total}',
        // When `showProgressDetails` is set, shows an estimation of how long the upload will take to complete.
        xTimeLeft: '%{time} restant',
        // Used as the label for the button that starts an upload.
        uploadXFiles: {
            0: 'Téléverser %{smart_count} fichier',
            1: 'Téléverser %{smart_count} fichiers',
        },
        // Used as the label for the button that starts an upload, if another upload has been started in the past
        // and new files were added later.
        uploadXNewFiles: {
            0: 'Téléverser +%{smart_count} fichier',
            1: 'Téléverser +%{smart_count} fichiers',
        },
    },
};

export const webcamLocale = {
    strings: {
        smile: 'Souriez!',
        // Used as the label for the button that takes a picture.
        // This is not visibly rendered but is picked up by screen readers.
        takePicture: 'Prendre une photo',
        // Used as the label for the button that starts a video recording.
        // This is not visibly rendered but is picked up by screen readers.
        startRecording: 'Enregistrer un vidéo',
        // Used as the label for the button that stops a video recording.
        // This is not visibly rendered but is picked up by screen readers.
        stopRecording: "Arrêter l'enregistrement",
        // Title on the “allow access” screen
        allowAccessTitle: 'Veuillez donner accès à la caméra',
        // Description on the “allow access” screen
        allowAccessDescription: 'Nous avons besoin de la caméra pour créer votre avatar.',
    },
};
