import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
// import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';

// import PageMeta from '../partials/PageMeta';
import Upload from '../partials/Upload';
import Result from '../partials/Result';

import Camera from '../icons/Camera';

import { uploadFile } from '../../lib/api';

import {
    setImage as setImageAction,
    setResult as setResultAction,
} from '../../actions/SiteActions';

import styles from '../../../styles/pages/home.scss';

// const messages = defineMessages({
//     metaTitle: {
//         id: 'meta.title',
//         defaultMessage: 'Saluut',
//     },
// });
// <PageMeta title={messages.metaTitle} />

const propTypes = {
    width: PropTypes.number.isRequired,
    setImage: PropTypes.func.isRequired,
    setResult: PropTypes.func.isRequired,
    results: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
        }),
    ),
};

const defaultProps = {
    results: [],
};

const HomePage = ({ width, setImage, setResult, results }) => {
    const onComplete = useCallback(imageFile => {
        uploadFile(imageFile).then(response => {
            if (response.data.url) {
                setImage(response.data);
                setResult(response.data);
            }
        });
    });

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                {results.map(res => (
                    <Result
                        className={styles.result}
                        count={Math.ceil(width / 500)}
                        key={res.url}
                        url={res.url}
                        width={res.width}
                        height={res.height}
                        ratio={res.ratio}
                        divider={width < 768 ? 2 : 1}
                    />
                ))}
            </div>
            <div className={styles.add}>
                <span>Ajoutez votre photo</span>
            </div>
            <Upload className={styles.upload} onComplete={onComplete}>
                <Camera />
            </Upload>
        </div>
    );
};

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({
        site: { image, results },
        layout: {
            size: { width },
        },
    }) => ({
        image,
        results,
        width,
    }),
    dispatch => ({
        setImage: image => dispatch(setImageAction(image)),
        setResult: image => dispatch(setResultAction(image)),
    }),
)(HomePage);

// const WithRouterContainer = withRouter(WithStateContainer);

export default WithStateContainer;
