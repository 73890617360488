import { post } from 'axios';
import { dataURItoBlob } from './utils';

export const uploadUrl = '/api/upload';

export const uploadFile = file => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    formData.append('file', file);
    return post(uploadUrl, formData, config);
};

export const uploadDataUri = file => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    formData.append('file', dataURItoBlob(file));
    return post(uploadUrl, formData, config);
};
