import {
    SET_ERRORS,
    RESET_REQUEST,
    SET_IMAGE,
    SET_RESULT,
    SET_RESULTS,
} from '../actions/SiteActions';

const initialState = {
    errors: null,
    statusCode: null,
    messages: null,
    image: null,
    results: [],
};

const SiteReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    switch (action.type) {
        case SET_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case SET_IMAGE:
            return {
                ...state,
                image: action.payload,
            };
        case SET_RESULT:
            if (state.results.find(res => res.url === action.payload.url) || false) {
                return {
                    ...state,
                };
            }

            return {
                ...state,
                results: [action.payload, ...state.results],
            };
        case SET_RESULTS:
            return {
                ...state,
                results: action.payload.concat(state.results),
            };
        case RESET_REQUEST:
            return {
                ...state,
                errors: null,
                statusCode: null,
                messages: null,
            };
        default:
            return state;
    }
};

export default SiteReducer;
