/* global FB */
import isObject from 'lodash/isObject';

export const pascalCase = str =>
    str
        .replace(/[^a-z0-9]+/gi, ' ')
        .replace(/(\w)(\w*)/g, (g0, g1, g2) => `${g1.toUpperCase()}${g2.toLowerCase()}`)
        .replace(/\s+/gi, '');

export const isMessage = message => isObject(message) && typeof message.id !== 'undefined';

export const getComponentFromName = (name, components, defaultComponent = null) => {
    const componentName = pascalCase(name);
    return components[componentName] || defaultComponent;
};

export const dataURItoBlob = dataURI => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
};

export const shareFacebook = (url, callback = null) => {
    if (typeof FB !== 'undefined') {
        FB.ui(
            {
                method: 'share',
                href: url,
            },
            response => {
                if (callback !== null) {
                    callback(response);
                }
            },
        );
    }
};

export const shareBrowser = (
    url,
    title = 'Faut que ça change, maintenant!',
    description = '',
    callback = null,
) => {
    if (navigator && navigator.share) {
        navigator
            .share({
                title,
                description,
                url,
            })
            .then(() => {
                console.log('It worked'); // eslint-disable-line
                if (callback !== null) {
                    callback();
                }
            })
            .catch(console.error);
    }
};
