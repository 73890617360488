/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/partials/result.scss';

const propTypes = {
    url: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    divider: PropTypes.number.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    url: null,
    className: null,
};

const Result = ({ url, width, height, divider, className }) => {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={{
                backgroundImage: `url(${url})`,
                backgroundSize: `${width / divider} ${height / divider}`,
            }}
        >
            <div
                className={styles.box}
                style={{ width: width / divider, height: height / divider }}
            />
        </div>
    );
};

Result.propTypes = propTypes;
Result.defaultProps = defaultProps;

export default Result;
